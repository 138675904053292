import Web3 from 'web3/dist/web3.min.js';

const Dapp = {
  account: null,
  accounts: null,
  networkId: -1,
  async initIfConnected (store) {
    console.log('initIfConnected')
    if (getCookie('web_account') == null) {
      return false;
    }

    console.log('initIfConnected cookie found')

    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    } else {
      console.log('else ethereum initIfConnected');
      Dapp.destroySession(store);
      return false;
    }

    web3.eth.net.getId().then((id) => {
      Dapp.networkId = id;
    });

    Dapp.account = getCookie('web_account'); //web3.eth.accounts.currentProvider.selectedAddress;
    web3.eth.defaultAccount = Dapp.account;

    if (Dapp.account) {
      await Dapp.createSession(store);
    } else {
      console.log('else account initIfConnected');
      Dapp.destroySession(store);
    }

    return Dapp.account;
  },
   async connect (store) {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      Dapp.accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    } else {
      throw('There was an error connecting with MetaMask. Please try again.');
    }

    const networkId = await web3.eth.net.getId()

    if (networkId != 137) {
      throw('Please connect to Polygon/MATIC network.')
    }

    Dapp.account = web3.eth.accounts.currentProvider.selectedAddress;
    web3.eth.defaultAccount = Dapp.account;

    await Dapp.createSession(store);

    return Dapp.account;
  },
  async disconnect (store) {
    console.log('disconnect store');
    await Dapp.destroySession(store);
    window.location.href = '/';
  },
  async createSession (store) {
    store.commit('setAddress', Dapp.account);
    await axios.post('/authentication/new_session', { address: Dapp.account });
    setCookie('web_account', Dapp.account, 30);
    VueEvent.fire('signIn', { account: Dapp.account });
  },
  async destroySession (store) {
    console.log('Session destroyed');
    console.trace();
    eraseCookie('web_account');
    await axios.delete('/authentication');
    Dapp.account = null;
    store.commit('setAddress', Dapp.account);
  },
  sign (msg) {
    return new Promise((resolve, reject) =>
      web3.eth.personal.sign(
        web3.utils.utf8ToHex(msg),
        Dapp.account,
        (err, signature) => {
          if (err) return reject(err);
          return resolve({ address: Dapp.account, signature });
        }
      )
    );
  },
  getContract (abi, address) {
    if (window.web3.eth) {
      const contract = new window.web3.eth.Contract(abi, address);
      return contract;
    } else {
      return null;
    }
  }
};

window.Dappa = Dapp;
export default Dapp;