<template>
  <div>
    <div class="card">
      <h4 class="card-header">Create a new collection</h4>
      <div class="card-body">
        <form @submit.prevent="createCollection">
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" class="form-control" id="name" placeholder="Something like CryptoPunks" v-model="name">
          </div>
          <div class="mb-3">
            <label for="symbol" class="form-label">Symbol</label>
            <input type="text" class="form-control" id="symbol" placeholder="Abbreviation or a symbol like Ͼ" v-model="symbol">
          </div>
          <button class="btn btn-primary" :disabled="loading">Create Collection</button>
        </form>
      </div>
      <div class="card-footer text-muted" v-if="message">
        {{ message }}
      </div>
      <div class="card-footer text-muted" v-else-if="deployedAddress">
        Your collection has been deployed at address <code>{{ deployedAddress }}</code>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  props: ['address'],
  data () {
    return {
      name: null,
      symbol: null,
      loading: false,
      message: null,
      deployedAddress: null
    }
  },
  methods: {
    async createCollection () {
      this.message = null;
      this.deployedAddress = null;
      var that = this;
      this.loading = true;
      console.log(that.address)
      const response = await axios.get('/collections/new_contract');
      const json = response.data.source;
      const contractId = response.data.id;
      var contractArgs = [that.name, that.symbol];
      var abi = json.abi;
      var deployingContract = new web3.eth.Contract(abi).deploy({
        data: json.bytecode,
        arguments: contractArgs
      });

      var estimatedGas = await deployingContract.estimateGas();

      var deployedContract = await deployingContract.send({
        from: that.address,
        gas: estimatedGas
      })

      const token = await axios.post('/collections', { deployed_contract: { address: deployedContract.options.address, arguments: contractArgs, contract_factory_id: contractId }});
      that.$emit('added-token', token.data);
      that.loading = false;
      that.deployedAddress = deployedContract.options.address;
      this.name = null
      this.symbol = null
    }
  }
}
</script>

<style scoped>
  
</style>