// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// import Vue from 'vue'
// import App from '../app.vue'

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import App from '../components/app.vue'
import Dapp from '../lib/dapp'

import { NFTStorage, File, Blob } from 'nft.storage/dist/bundle.esm.min.js'
const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDRmNEFkYkI2MmQ0OTExZDg3RUJmMTNkZDc2MDdjQjk3MjNGNzFkZkQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY1OTA5NDM3MzQ2MSwibmFtZSI6Im1pbnRlcmV1bSJ9.TS99lfrYZcqcm88CuVXLBusuNowlNrmS0f29DBMDdeg'
window.nftStorageClient = new NFTStorage({ token: NFT_STORAGE_TOKEN })

import axios from "axios";

import navbar_component from '../components/navbar.vue'
import AddItemERC721 from '../components/AddItemERC721'

const bootstrap = require("bootstrap");
// window.bootstrap = bootstrap;

import "stylesheets/application" // For css

Vue.use(Vuex);

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import store from '../store/account'

// window.Store = store;
// window.App = App;
window.axios = axios;

class GlobalEvent {
  constructor() {
    this.vue = new Vue();
  }

  fire(event, data = null) {
    this.vue.$emit(event, data);
  }

  listen(event, callback) {
    this.vue.$on(event, callback);
  }
}

window.VueEvent = new GlobalEvent();

document.addEventListener('DOMContentLoaded', () => {
  setupCSRF();
  const app = new Vue({
    store,
    el: '#root',
    components: { "app": App, navbar_component, "add_item_erc721": AddItemERC721 }
  })
})

const setupCSRF = function() {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
}

window.setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
window.getCookie = (name)  => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' '){
      c = c.substring(1,c.length);
    }
    if (c.indexOf(nameEQ) == 0){
      return c.substring(nameEQ.length,c.length);
    }
  }
  return null;
}
window.eraseCookie = (name) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
