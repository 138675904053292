import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import Dapp from '../lib/dapp';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    account: null,
  },
  
  getters: {
    // Here we will create a getter
    maskedAddress (state) {
      if (state.account) {
        return state.account.substr(0,6) + "..." + state.account.substr(38);
      } else {
        return null;
      }
    }
  },
  
  mutations: {
    // Here we will create Jenny
    setAddress (state, address) {
      state.account = address;
    }
  },
  
  actions: {
    // Here we will create Larry
    // async connectMetamask ({ commit }) {
    //   await Dapp.connect()
    //   commit('setAddress', Dapp.account);
    //   await axios.post('/authentication/new_session', { address: Dapp.account });
    // }
  }
});
