<template>
  <div>
    <div class="mt-2" v-if="!account">
      <!-- <h1>Simple NFT Creator!</h1>
      <h3>Mint, Sell, and Trade using your own Smart Contract</h3> -->
      <div class="p-4 p-md-5 mb-4 w-75 m-auto">
        <div class="px-0 text-center">
          <h1 class="display-4">Simple NFT Maker!</h1>
          <p class="lead my-3">Mint, Sell, and Trade using your own Smart Contract on Polygon/MATIC chain.</p>
          <button v-on:click="connectWallet" class="btn btn-primary mb-5">Connect MetaMask</button>
          <hr />
        </div>
        <div class="mt-3">
          <!-- <h3>Why should I use Mintereum to create NFT?</h3>
          <ul>
            <li>No fees, just pay the gas, which is negligible on Polygon.</li>
            <li>Leverages IPFS so your NFTs can truly live forever.</li>
          </ul> -->
          <div class="row mb-5 mt-5 d-flex align-items-center">
            <div class="col-md-6">
              <img :src="free" class="img-fluid">
            </div>
            <div class="col-md-6 ">
              <h4 class="display-5">No fees</h4>
              <p>Just pay the gas, which is negligible on the Polygon/MATIC network.</p>
            </div>
          </div>
          <div class="row mb-5 mt-5 d-flex align-items-center">
            <div class="col-md-6">
              <img :src="own_contract" class="img-fluid">
            </div>
            <div class="col-md-6 ">
              <h4 class="display-5">Your own collection</h4>
              <p>You don't need to rely on OpenSea, or Rarible's collection. Own your collection and contract, and customize its identity.</p>
            </div>
          </div>
          <div class="row mb-5 mt-5 d-flex align-items-center">
            <div class="col-md-6">
              <img :src="forever" class="img-fluid">
            </div>
            <div class="col-md-6 ">
              <h4 class="display-5">They live forever</h4>
              <p>We store all your images and data on IPFS so no one can tamper with your NFT. Not even us (or you)!</p>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button v-on:click="connectWallet" class="btn btn-primary mt-5">Connect MetaMask</button>
          <p>(Adding more wallets soon)</p>
        </div>
      </div>
    </div>
    <div v-if="contracts.length > 0">
      <div class="row">
        <div class="col-lg-8 mb-2">
          <div class="card">
            <h4 class="card-header">Your collections</h4>
            <ul class="list-group list-group-flush">
              <li v-for="contract in contracts" class="list-group-item">
                <a :href="'/collections/' + contract.id ">{{ contract.arguments[0] }} ({{ contract.arguments[1] }})</a>
              </li>
            </ul>
          </div>
        </div>
        <ERC721Form @added-token="tokenAdded" class="col-lg-4 mb-2" :address="account" v-if="account"/>
      </div>
    </div>
    <div v-else>
      <div class="col-lg-4 offset-lg-4 mb-2" v-if="account">
        <p>Enter the name and symbol of your collection. You can't change this once you create a contract (but you can always create more).</p>
        <ERC721Form @added-token="tokenAdded" :address="account"/>
      </div>
    </div>
  </div>
</template>

<script>
import Dapp from '../lib/dapp.js';
import ERC721Form from './ERC721Form';
import Free from '../images/undraw_make_it_rain_iwk4.svg';
import OwnContract from '../images/undraw_deliveries_131a.svg';
import Forever from '../images/undraw_ethereum_desire_wy1b.svg';

const app = {
  data () {
    return {
      contracts: [],
      free: Free,
      own_contract: OwnContract,
      forever: Forever
    }
  },
  props: ['pCollection'],
  mounted () {
    if (this.pCollection) {
      this.contracts = JSON.parse(this.pCollection);
    } else {
      VueEvent.listen('signIn', (data) => {
        this.loadCollections();
      })
    }
  },
  computed: {
    account () {
      return this.$store.state.account;
    }
  },
  watch: {
    account (newAccount, oldAccount) {
      // if (newAccount) {
      //   this.loadCollections();
      // } else {
      //   this.contracts = [];
      // }
    }
  },
  methods: {
    connectWallet () {
      var that = this;
      Dapp.connect(this.$store).then(() => {}).catch((error) => {
        console.error(error);
        alert(error);
      });
    },
    async loadCollections () {
      var response = await axios.get('/collections');
      this.contracts = response.data;
    },
    tokenAdded (token) {
      console.log(JSON.stringify(token));
      this.contracts.push(token)
    }
  },
  components: {
    ERC721Form
  }
}

export default app;
</script>

<style scoped>

</style>
