<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <p class="mb-5">
          NFTs in this Collection
          <button class="btn btn-secondary btn-sm float-end" type="button" data-bs-toggle="modal" data-bs-target="#new-token-modal" @click="resetModal">Add token</button>
        </p>
        <div class="text-center mt-5 pt-5" v-if="tokens.length <= 0">
          <p class="lead">Looks like there are no NFTs in your collection. Let's add one!</p>
          <button class="btn btn-secondary" type="button" data-bs-toggle="modal" data-bs-target="#new-token-modal">Add token to this collection</button>
        </div>
        <div class="row">
          <div class="col-md-3" v-for="token in tokens">
            <div class="card mb-2">
              <div class="card-body bg-primary text-white">
                <h5 class="card-title">{{ token.metadata.name || token.metadata.title }}</h5>
                <p class="card-text">{{ token.metadata.description }}</p>
              </div>
              <div class="image">
                <img :src="ipfsToPinata(token.metadata.image)" class="card-img-bottom img-fluid mx-auto d-block" />
              </div>
              <div class="card-footer text-muted text-end">
                <a :href="ipfsToPinata(token.metadata.image)" class="link-secondary" target="_blank">Original asset</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="new-token-modal" tabindex="-1" aria-labelledby="modal-label" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modal-label">Mint new item in this collection</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="addItem">
              <div class="modal-body">
                <div class="mb-3">
                  <label for="name" class="form-label">Name</label>
                  <input type="text" class="form-control" id="name" placeholder="Name.." v-model="name">
                </div>
                <div class="mb-3">
                  <label for="description" class="form-label">Description</label>
                  <textarea type="text" class="form-control" id="description" placeholder="Description.." v-model="description"></textarea>
                </div>
                <div class="mb-3">
                  <label for="external_url" class="form-label">Link (Optional)</label>
                  <input type="url" class="form-control" id="external_url" placeholder="Link.." v-model="external_url">
                </div>
                <FileUpload @file-uploaded="onFileUpload" @file-uploading="setStage('file-uploading')" ref="fileUpload" />
              </div>
              <div class="modal-footer">
                <p>{{message}}</p>
                <button :disabled="formDisabled" type="button" class="btn btn-primary" @click="addItem">Mint</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dapp from '../lib/dapp.js';
import FileUpload from './FileUpload';

export default {
  props: ['collection', 'tokenlist', 'pabi'],
  data () {
    return {
      abi: null,
      address: null,
      fileIPFS: null,
      name: null,
      description: null,
      external_url: null,
      contract: null,
      message: null,
      processing: false,
      tokens: []
    }
  },
  mounted () {
    const collection = JSON.parse(this.collection);
    const tokens = JSON.parse(this.tokenlist);
    const abi = JSON.parse(this.pabi);
    this.abi = abi;
    this.address = collection.address;
    this.id = collection.id;
    this.contract = Dapp.getContract(this.abi, this.address);
    this.tokens = tokens;
  },
  methods: {
    explorerAddress (address) {
      return `https://explorer-mainnet.maticvigil.com/address/${address}`;
    },
    resetModal (e) {
      this.name = null;
      this.description = null;
      this.fileIPFS = null;
      this.external_url = null;
      this.$refs.fileUpload.clear();
      this.processing = false;

      if (e) {
        this.message = null;
      }
    },
    async addItem () {
      if (this.formDisabled) {
        return;
      }

      if (!this.isValidHttpUrl(this.external_url)) {
        alert('Link is not a valid URL');
        return;
      }

      this.processing = true;

      var that = this;
      this.setStage('uploading-tokenURI');
      const content = {
        name: that.name,
        description: that.description,
        image: that.fileIPFS,
        external_url: that.external_url
      };

      const str = JSON.stringify(content);
      const bytes = new TextEncoder().encode(str);
      const blob = new Blob([bytes], {
          type: "application/json;charset=utf-8"
      });
      var response = await nftStorageClient.storeBlob(blob)
      const tokenURI = `ipfs://${response}`;
      console.log('tokenURI', tokenURI)
      this.setStage('uploaded-tokenURI');
      this.contract = Dapp.getContract(this.abi, this.address);
      const tx = await this.contract.methods.safeMint(Dapp.account, tokenURI).send({ from: Dapp.account });
      this.setStage('transaction-succeeded')
      response = await axios.post(`/collections/${this.id}/tokens`, {
        token: {
          metadata: content,
          token_uri: tokenURI,
          index: tx.events.Transfer.returnValues.tokenId
        }
      });
      this.tokens.push(response.data);
      this.setStage('complete');
      that.resetModal(null);
    },
    setStage (stage) {
      switch (stage) {
        case 'file-uploading':
          this.message = "Uploading your file to IPFS.";
          break;
        case 'file-uploaded':
          this.message = "Uploaded your file to IPFS.";
          break;
        case 'uploading-tokenURI':
          this.message = "Creating metadata for your NFT.";
          break;
        case 'uploaded-tokenURI':
          this.message = "Uploaded metadata for your NFT. Waiting for transaction to complete.";
          break;
        case 'transaction-succeeded':
          this.message = "Transaction is successful! Saving your data to server.";
          break;
        case 'complete':
          this.message = "You have successfully minted a new NFT!";
      }
    },
    onFileUpload (fileIPFSHash) {
      console.log('onFileUpload', fileIPFSHash);
      this.setStage('file-uploaded')
      this.fileIPFS = `ipfs://${fileIPFSHash}`;
    },
    ipfsToPinata (ipfs) {
      const cid = ipfs.substr(7);
      return `https://ipfs.io/ipfs/${cid}`;
    },
    isValidHttpUrl (string) {
      if (string == null) return true;
      if (string.trim() === "") return true;

      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;  
      }
      return url.protocol === "http:" || url.protocol === "https:";
    }

  },
  computed: {
    formDisabled () {
      var nameEnabled = this.name && this.name.length > 0;
      var descriptionEnabled = this.description && this.description.length > 0;
      var fileIPFSEnabled = this.fileIPFS && this.fileIPFS.length > 0;
      return !(nameEnabled && descriptionEnabled && fileIPFSEnabled && this.isValidHttpUrl(this.external_url)) || this.processing;
    }
  },
  components: {
    FileUpload
  }
}
</script>

<style scoped>

/*.image{
    position:relative;
    overflow:hidden;
    padding-bottom:100%;
}
.image img{
    position:absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
*/
img {
  object-fit: contain;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.no-underline {
  text-decoration: none;
}

</style>