<template>
  <div>
    <div v-if="filePreview">
      <img :src="filePreview" class="img-fluid rounded mx-auto d-block mb-3">
      <p class="small text-muted">Refresh page if you want to upload a different image.</p>
    </div>
    <div class="files" v-else>
      <label class="form-label" for="file">Upload your file, or Drop it here.</label>
      <input type="file" class="form-control" id="file" v-cloak @drop.prevent="handleFileDrop" @dragover.prevent @change="handleFileInput">
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  data () {
    return {
      file: null,
      filePreview: null,
    }
  },
  methods: {
    clear () {
      this.file = null;
      this.filePreview = null;
    },
    handleFileDrop (e) {
      console.log('handleFileDrop');
      let droppedFiles = e.dataTransfer.files;
      if(!droppedFiles) return;
      this.addFile(droppedFiles[0]);
    },
    handleFileInput (e) {
      console.log('handleFileInput');
      let files = e.target.files;
      if(!files) return;
      this.addFile(files[0]);
    },
    addFile (file) {
      this.$emit('file-uploading');
      console.log("File type: " + file.type);
      if (file.type.split("/")[0] == "image") {
        this.file = file;
        this.previewFile();
      } else {
        alert('Unsupported content type. We only support images right now!');
      }
    },
    async previewFile () {
      const that = this;
      let reader = new FileReader()
      reader.readAsDataURL(that.file)
      reader.onloadend = function() {
        that.filePreview = reader.result;
      }

      var response = await nftStorageClient.storeBlob(that.file)
      this.$emit('file-uploaded', response);
    },
  }
}
</script>
<style type="text/css">
.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus{
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  border:1px solid #92b0b3;
}
.files{
  position: relative;
}
/*.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}*/
.color input{
  background-color:#f1f1f1;
}
/*.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here.";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}*/
</style>