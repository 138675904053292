<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-success text-white mb-2">
    <div class="container">
      <a class="navbar-brand text-white" href="/">
        <img :src="logo" alt="" width="24" height="24" class="d-inline-block align-text-top" id="logo" />
        Mintereum
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-if="this.$store.state.account">
            <a class="nav-link text-white" href="#" :title="addressTitle" @click.prevent="disconnectWallet">{{ this.$store.getters.maskedAddress }}</a>
          </li>
          <li class="nav-item" v-else>
            <button v-on:click="connectWallet" class="btn btn-link nav-link">Connect MetaMask</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Dapp from '../lib/dapp.js'
import Logo from '../images/leaf.svg'

export default {
  data () {
    return {
      logo: Logo
    }
  },
  mounted () {
    Dapp.initIfConnected(this.$store);
  },
  computed: {
    address () {
      return this.$store.state.account;
    },
    addressTitle () {
      return `Address: ${this.address}. Click to logout.`
    }
  },

  methods: {
    connectWallet () {
      var that = this;
      Dapp.connect(this.$store).then(() => {}).catch((e) => {
        alert(e);
      });
    },
    async disconnectWallet () {
      await Dapp.disconnect(this.$store);
    }
  }
}
</script>

<style scoped>
#logo {
  fill: red;
}
</style>
